import './App.css';
import { Navbar } from './components/navbar';
import { Form } from './components/main';
function App() {
  return (
    <div className="App">
      <Navbar />
      <Form/>
    </div>
  );
}

export default App;
